.home {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;

  &__maplist {
    width: 40%;
    height: 45%;
    background: url('../../images/Placeholder_Map_Middle.jpg') center no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 22.5vh;
    padding: 1% 0.4% 0.7% 1%;

    &__wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow: auto;

      &__list {
        width: 32.5%;
        margin-right: 0.75%;
        cursor: pointer;
        position: relative;

        img {
          width: 100%;

          &:nth-child(2) {
            opacity: 0.85;
            position: absolute;
            z-index: 2;
            left: 0%;
          }
        }
      }
    }
  }

  &__banned {
    width: 30%;
    height: 50%;
    position: absolute;
    left: 0%;
    bottom: 15%;

    &__title {
      width: 40%;
      margin: 0 auto;
      margin-top: -2%;

      img {
        width: 100%;
      }
    }

    &__team {
      width: 70%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #0e0e0e;
      position: absolute;
      top: 11%;
      left: 5%;

      input {
        width: 100%;
        background-color: transparent;
        border: none;
        font-family: Refrigerator;
        font-size: 3vh;
        text-align: center;
        cursor: pointer;
      }
    }

    &__wrapper {
      width: 100%;
      height: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0%;
      bottom: 3%;
      padding: 1% 5% 0% 5%;

      &__column {
        width: 100%;
        height: 32.5%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 1%;

        &__banned {
          width: 100%;
          height: 95%;
          background-color: #202a36;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: relative;
          border: 1px solid yellow;
          margin-right: 2%;
          cursor: pointer;

          img {
            width: 25%;
          }

          p {
            font-size: 2.25vh;
            color: #d5ba1d;
            position: absolute;
            top: 1.5%;
            left: 4%;
          }
        }

        &__icon {
          width: 60%;
          height: 100%;
          display: flex;
          align-items: center;
          position: relative;

          img {
            width: 100%;
            height: 97.5%;
            cursor: pointer;

            &:nth-child(2) {
              width: 2vh;
              height: 2vh;
              position: absolute;
              top: 7.5%;
              right: 6%;
            }
          }
        }
      }
    }
  }

  &__picked {
    width: 30%;
    height: 50%;
    position: absolute;
    right: 0%;
    bottom: 15%;

    &__title {
      width: 40%;
      margin: 0 auto;
      margin-top: -2.5%;

      img {
        width: 100%;
      }
    }

    &__team {
      width: 70%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #0e0e0e;
      position: absolute;
      top: 11%;
      right: 5%;

      p {
        width: 100%;
        background-color: transparent;
        border: none;
        font-family: Refrigerator;
        font-size: 3vh;
        text-align: center;
        cursor: pointer;
      }
    }

    &__wrapper {
      width: 100%;
      height: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0%;
      bottom: 3%;
      padding: 1% 5% 0% 5%;

      &__column {
        width: 100%;
        height: 32.5%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 1%;

        &__picked {
          width: 100%;
          height: 95%;
          background-color: #202a36;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: relative;
          border: 1px solid yellow;
          margin-left: 2%;
          cursor: pointer;

          &__ribbon {
            width: 25%;
            position: absolute;
            z-index: 2;
          }

          span {
            font-size: 2.25vh;
            color: #d5ba1d;
            position: absolute;
            top: 1.5%;
            left: 4%;
            z-index: 2;
          }

          &__team {
            background: transparent !important;
            color: #d5ba1d !important;
          }

          p {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #d5ba1d;
            text-align: center;
            color: #0e0e0e;
            padding: 0% 2.5%;
            position: absolute;
            top: 0%;
            z-index: 1;

            &.disable {
              background-color: transparent;
            }
          }
        }

        &__icon {
          width: 60%;
          height: 100%;
          display: flex;
          align-items: center;

          img {
            width: 100%;
            height: 97.5%;
          }
        }
      }
    }
  }

  &__bottom {
    width: 35%;
    height: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 12%;
    background: url('../../images/Placeholder_Coin.png') center no-repeat;
    background-size: 100% 100%;
    padding: 0% 2%;

    &.confirm {
      background: url('../../images/Placeholder_Confirm.png') center no-repeat;
      background-size: 100% 100%;

      .wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          font-size: 2.75vh;
          color: #a79494;
          margin-bottom: 3%;
        }

        img {
          width: 40%;
          cursor: pointer;
        }

        .button {
          width: 40%;
          height: 25%;
          background: url('../../images/Btn_Konfirmasi.png') center no-repeat;
          background-size: 100% 100%;
          color: transparent;
          cursor: pointer;

          &:hover {
            background: url('../../images/Btn_Konfirmasi_hover.png') center
              no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

    &__coin {
      width: 32.5%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 60%;
        margin-bottom: 2.5%;
        margin-left: 15%;
      }

      p {
        width: 86%;
        text-align: center;
        text-transform: uppercase;
      }
    }

    &__mode {
      width: 67.5%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__top {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5%;

        p {
          margin-right: 5%;
        }

        img {
          width: 17.5%;
          margin-right: 2%;
          cursor: pointer;

          &.disable {
            opacity: 0.3;
          }
        }
      }

      &__bot {
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: transparent;

        &__button {
          width: 60%;
          height: 45%;
          background: url('../../images/Btn_Maplist.png') center no-repeat;
          background-size: 100% 100%;
          cursor: pointer;

          &:hover {
            background: url('../../images/Btn_Maplist_hover.png') center
              no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
