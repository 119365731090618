input,
textarea,
button,
select,
img,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #0523227c;
}

::-webkit-scrollbar-thumb {
  background: #d5ba1d;
}
