* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  width: 100vw;
  height: 100vh;
  background: url('../images/BG.jpg') center no-repeat;
  background-size: 100% 100%;
  font-family: Refrigerator;
  font-size: 3vh;
  color: #fefefe;
}

@import './fonts';
@import './helper';
@import './pages/home';
@import './partials/modal';
@import './partials/coin';
