$cdn: 'https://cdngarenanow-a.akamaihd.net/webid/AOV/changefate/';

.react-responsive-modal-overlay.overlay {
  padding: 0;

  .modal {
    width: 120vh;
    max-width: none;
    height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: url('../../images/Placeholder_Popup.png') center no-repeat;
    background-size: 100% 100%;
    padding: 0;

    .title {
      height: 15%;
      display: flex;
      align-items: center;
      margin-top: 6%;

      .btn-close {
        position: absolute;
        height: 5%;
        right: 1.5%;
        top: 2.5%;
        cursor: pointer;

        > img {
          max-height: 100%;
        }
      }

      > span {
        color: #d5ba1d;
        font-size: 7.5vh;
        padding-top: 5%;
        text-transform: uppercase;
      }

      > img {
        max-height: 140%;
        max-width: 100%;
      }
    }

    .wrapper {
      height: 85%;
      width: 100%;
      padding: 2.5% 1% 1% 1%;
    }

    // BANPICK
    .banpick {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &__list {
        width: 90%;
        height: 75%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: auto;
        padding: 0% 2.5% 0% 5%;
        margin-bottom: 1%;

        &__item {
          width: 24%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0% 0.5% 2.5% 0.5%;

          img {
            width: 100%;
            cursor: pointer;
          }

          &.disable {
            filter: grayscale(1);
            opacity: 0.5;

            img {
              cursor: unset;
            }
          }
        }
      }

      &__close {
        width: 25%;
        position: absolute;
        bottom: 7.5%;
        cursor: pointer;

        img {
          width: 100%;
        }
      }
    }

    // SWITCH MODE
    .switch_mode {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &__list {
        width: 75%;
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0% 2.5% 0% 5%;
        margin-bottom: 1%;

        &__item {
          width: 24%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0% 0.5% 2.5% 0.5%;

          img {
            width: 100%;
            cursor: pointer;
          }

          &.disable {
            filter: grayscale(1);
            opacity: 0.25;

            img {
              cursor: unset;
            }
          }
        }
      }

      &__close {
        width: 25%;
        position: absolute;
        bottom: 7.5%;
        cursor: pointer;

        img {
          width: 100%;
        }
      }
    }
  }
}
